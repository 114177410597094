import CookieConsent from "./CookieConsent";
window.$ = window.jQuery = require(`jquery`);

const addCookieConsentMarkup = () => {
  const cookieConsentRoot = document.createElement(`div`);
  cookieConsentRoot.setAttribute(`id`, `ipa-cookie-consent`);
  document.body.insertAdjacentElement(`beforeend`, cookieConsentRoot);
  cookieConsentRoot.insertAdjacentHTML(
    `afterbegin`,
    `<cookie-consent></cookie-consent>`
  );
};
$(function () {
  addCookieConsentMarkup();
});

import Vue from "vue";

const cookieConsentInterval = setInterval(function () {
  const elem = document.querySelector(`#ipa-cookie-consent`);
  console.log(`cookieConsentInterval running`);

  if (!elem) {
    return;
  }

  console.log(`cookieConsentInterval stopped`);
  clearInterval(cookieConsentInterval);

  setTimeout(function () {
    const app = new Vue({
      el: `#ipa-cookie-consent`,
      components: {
        "cookie-consent": CookieConsent,
      },
    });
  }, 90);
}, 450);
