var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "slide-down" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.consent_accepted,
            expression: "!consent_accepted",
          },
        ],
        ref: "cookie_consent_element",
        staticClass: "ipa-cookie-consent",
      },
      [
        _c("div", [
          _vm._v(
            "\n      This website uses cookies. By continuing to browse this website you are\n      agreeing to our use of cookies.\n      "
          ),
        ]),
        _vm._v(" "),
        _c("button", { on: { click: _vm.hideBox } }, [
          _vm._v("Accept cookies"),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }