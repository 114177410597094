<template>
  <transition name="slide-down">
    <div
      v-show="!consent_accepted"
      ref="cookie_consent_element"
      class="ipa-cookie-consent"
    >
      <div>
        This website uses cookies. By continuing to browse this website you are
        agreeing to our use of cookies.
        <!--        Find out more by viewing our <a href="#" target="_blank">cookie policy</a>-->
      </div>
      <button @click="hideBox">Accept cookies</button>
    </div>
  </transition>
</template>
<style lang="scss" scoped class="ipa-cookie-consent-styles">
div,
span,
p,
a,
small,
strong,
b,
u,
i,
ol,
ul,
li {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

* {
  box-sizing: border-box;
}

.ipa-cookie-consent {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.8rem;
  background: var(--bar-background-color);
  color: var(--bar-text-color);
  bottom: 0;
  left: 0;
  z-index: 20;
  font-family: "Montserrat", Arial;
  box-shadow: 0 -3px 3px 1px rgba(255, 255, 255, 0.36);

  @media (max-width: 450px) {
    flex-flow: column;
  }
}

button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
  background: var(--button-background-color);
  color: var(--button-text-color);

  @media (min-width: 774px) {
    padding: 18px 27px;
    margin-left: 1.8rem;
  }
  @media (max-width: 773px) {
    padding: 5px 9px;
    margin-left: 0.9rem;
  }

  @media (max-width: 450px) {
    width: 100%;
    margin: 9px 0 0 0;
    padding: 18px 27px;
  }
}

a {
  text-decoration: underline;
  color: var(--bar-text-color);

  &:hover {
    text-decoration: none;
  }
}

.slide-down-leave-active {
  transition: opacity 0.5s;
}

.slide-down-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
<script>
// @vue/component
export default {
  data() {
    return {
      consent_accepted: true,
      settings: {},
    };
  },
  mounted() {
    this.settings = window.ipaCookieConsentSettings;
    this.setCssVariables();
    if (localStorage.getItem(this.getLocalStorageKey())) {
      this.consent_accepted = true;
      return;
    }
    this.consent_accepted = false;
  },
  methods: {
    setCssVariables() {
      const barSettings = Object.entries(this.settings).forEach((property) => {
        this.$refs.cookie_consent_element.style.setProperty(
          `--${this.cameCaseToDash(property[0])}`,
          property[1]
        );
      });
    },
    cameCaseToDash(str) {
      return str.replace(/([a-z])([A-Z])/g, `$1-$2`).toLowerCase();
    },
    hideBox() {
      this.consent_accepted = true;
      const localStorageKey = this.getLocalStorageKey();
      localStorage.setItem(localStorageKey, `true`);
    },
    getLocalStorageKey() {
      return `${location.hostname}-ipa-cookie-consent-accepted`;
    },
  },
};
</script>
